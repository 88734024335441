<template>
    <v-card flat>
        <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-2">{{ $t('Login.title') }}</p>
            <p class="mb-2">{{ $t('Login.subtitle') }}</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
            <ValidationObserver v-slot="{ handleSubmit }">
                <v-form @submit.prevent="handleSubmit(login)">
                    <ValidationProvider ref="providerIdentifier" :name="$t('Login.fields.email')" rules="email|required" v-slot="{ errors }">
                        <v-text-field
                            :error-messages="errors"
                            v-model="form.identifier"
                            outlined
                            :label="$t('Login.fields.email')"
                            placeholder="john@example.com"
                            hide-details="auto"
                            class="mb-6"></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('Login.fields.password')" rules="required|not-hashed" v-slot="{ errors }">
                        <v-text-field
                            v-model="form.password"
                            outlined
                            :error-messages="errors"
                            :type="isPasswordVisible ? 'text' : 'password'"
                            :label="$t('Login.fields.password')"
                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                            hide-details="auto"
                            class="mb-2"
                            @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>
                    </ValidationProvider>
                    <div class="d-flex align-center justify-end flex-wrap">
                        <!-- forget link -->
                        <router-link :to="{ name: 'ForgotPassword' }"> {{ $t('Login.forgotPassword') }} </router-link>
                    </div>

                    <v-btn :loading="isLoading" block color="primary" type="submit" class="mt-6"> {{ $t('Login.fields.button') }} </v-btn>
                </v-form>
            </ValidationObserver>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text v-if="false" class="d-flex align-center justify-center flex-wrap mt-2">
            <p class="mb-0 me-2">New on our platform?</p>
            <a href="#"> Create an account </a>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
            <v-divider></v-divider>
            <span class="mx-5">{{ $t('shared.or') }}</span>
            <v-divider></v-divider>
        </v-card-text>

        <!-- socail links -->
        <v-card-actions class="d-flex justify-center">
            <v-btn icon>
                <v-icon color="#3b5998">{{ icons.mdiFacebook }}</v-icon>
            </v-btn>
            <v-btn icon>
                <v-img max-height="24" contain max-width="24" src="@/assets/icons/google.svg"></v-img>
            </v-btn>
            <v-btn icon>
                <v-icon color="#979797">{{ icons.mdiApple }}</v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon color="#EA3E23">{{ icons.mdiMicrosoftOffice }}</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    // eslint-disable-next-line object-curly-newline
    import { login } from '@/api/auth';
    import { mdiFacebook, mdiEyeOutline, mdiEyeOffOutline, mdiApple, mdiMicrosoftOffice } from '@mdi/js';
    import { ref } from '@vue/composition-api';
    import { get } from 'lodash';

    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        setup() {
            const isPasswordVisible = ref(false);
            return {
                isPasswordVisible,
                // Icons
                icons: {
                    mdiEyeOutline,
                    mdiEyeOffOutline,
                    mdiFacebook,
                    mdiApple,
                    mdiMicrosoftOffice,
                },
            };
        },
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        data() {
            return {
                form: {
                    identifier: 'demo@ioconnect.iotech.pt',
                    password: 'gykMU43pwX3vMDTbNVZ7c3nv',
                },
                rules: {},
                errorMessages: [],
                isLoading: false,
            };
        },
        methods: {
            async login() {
                this.isLoading = true;
                await login(this.form)
                    .then((response) => {
                        this.$store.dispatch('auth/onUserLogin', response.data);
                        this.$router.push("/");
                    })
                    .catch((error) => {
                        if (get(error, 'response.data.error.message')) {
                            this.$refs.providerIdentifier.applyResult({
                                errors: [this.$t(`Login.api.${error.response.data.error.message}`)],
                                valid: false,
                                failedRules: {},
                            });
                        } else {
                            console.log(error);
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@core/preset/preset/pages/auth.scss';
</style>
